<template>
  <div>
    <!-- 定额会员支付页面 -->
    <div>
      <HomePageTop/>
      <div>
        <div class="main zhu base-w">
          <div class="order" style="padding:0 200px">
            <div class="orderPayTypeList flexAndCenter" style="padding:30px 150px 0">
              <div v-for="(item,index) in orderPayTypeList" :key="index" class="active"
                   :style="{margin:index===1?'0 80px':''}">
                {{ $fanyi(item.name) }}
              </div>
            </div>
          </div>
          <div class="priceList">
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{
                  $fanyi('見積金額')
                }}：
              </div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">
                  {{ payValueText }}
                </div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{
                  $fanyi('支払い額')
                }}：
              </div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">
                  {{ payValueText }}
                </div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{
                  $fanyi('残高')
                }}：
              </div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">{{ $store.state.userInfo.balance }}</div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div class="flexAndCenter priceContainer">
              <div class="priceName color333">{{ $fanyi('残り') }}：</div>
              <div class="flexAndCenter priceNumContainer" style="margin-left: 6px;">
                <div class="fontSize14">
                  {{ $store.state.userInfo.balance - payValue }}
                </div>
                <div class="fontSize14 color333">{{ $fanyi('円') }}</div>
              </div>
            </div>
            <div style="display: flex;justify-content: flex-end">
              <div class="payBtn fontSize14" @click="payOrder">{{ $fanyi('支払い') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../../components/public/Dialog.vue";
import HomePageTop from "../../../../../components/head/HomePageTop.vue";

export default {
  data() {
    return {
      payValueText: '28,000',
      payValue: 28000,
      orderPayTypeList: [{
        name: '残金支払い'
      }],
      vipList: [],
      orderPayPriceInfo: {}
    };
  },
  mounted() {
  },
  created() {
    if (this.$route.query.days !== '') {
      this.$api.vipList().then((res) => {
        this.vipList = res.data;
        let status = false;
        this.vipList.forEach((item, index) => {
          if (item.days == this.$route.query.days) {
            status = true
            this.payValueText = item.pay;
            this.payValue = item.pay;
          }
          if (index === this.vipList.length - 1 && status === false) {
            this.$message.error('有料会員天数有误');
            setTimeout(() => {
              window.history.go(-1)
            }, 500)
          }
        })
      });
    } else {
      this.$message.error('有料会員天数有误');
      setTimeout(() => {
        window.history.go(-1)
      }, 500)
    }
  },
  components: {
    HomePageTop,
    Dialog,
  },
  methods: {
    //余额支付
    payOrder() {
      this.$api.balancePayVip({days: this.$route.query.days}).then((res) => {
        if (res.code != 0) {
          this.$message.error(this.$fanyi(res.msg));
          return;
        }
        setTimeout(() => {
          let obj = {
            type: 'balance',
            serial_number: res.data.serial_number,
            pay_amount_jpy: res.data.pay,
          };
          this.$store.commit("updatePayDetails", {obj: obj});
          let routeData = this.$router.resolve({
            path: "/balanceMaybeBankPaymentSuccess",
          });
          window.open(routeData.href, "_blank");
          window.close();
        }, 500)
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../../css/mixin.scss";
@import "../../../../../views/UserCenter/views/userOrder/views/css/orderDetails.scss";

.orderPayTypeList {
  margin: 30px 0;

  div {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    color: #B4272B;
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #B4272B;
  }

  .active {
    background: #B4272B;
    color: #fff;
  }
}

.priceContainer {
  margin-bottom: 26px;
  justify-content: space-between;

  .priceName {
    text-align: right;
    width: 80px;
    min-width: 80px;
  }

  .priceNumContainer {
    width: 280px;
    height: 34px;
    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    justify-content: space-between;
    padding: 0 20px 0 11px;

    div:first-child {
      color: #B4272B;
    }
  }
}

.paypalPricePaymentContainer {
  height: 731px !important;
  flex-direction: column;
  padding: 60px 0 0 !important;

  .paypalLogoContainer {
    border-radius: 4px;
    overflow: hidden;
    width: 320px;
    height: 108px;
    min-height: 108px;
    margin-bottom: 38px;

    div {
      width: 320px;
      height: 108px;
      border: 2px solid;
      border-image: linear-gradient(225deg, #2A3466, #498EBE) 2 2;
      justify-content: center;

      img {
        width: 200px;
        height: 48px;
      }
    }
  }

  .userInputPrice {
    .left {
      /deep/ .el-input__inner {
        height: 34px !important;
      }

      div {
        color: #B4272B;
        font-size: 12px;
        margin-top: 4px;
      }

      margin-right: 9px;
    }

    .postPrice {
      margin: 8px 0 2px;
    }
  }

  .paypalPayContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding-left: 238px;

    .priceContainer {
      justify-content: flex-end;
    }

    .priceName {
      max-width: 112px !important;
      width: 112px;
      text-align: right;
    }
  }
}

.priceList, .paypalPricePaymentContainer {
  height: 394px;
  border-radius: 3px;
  border: 1px solid #DFDFDF;
  margin: 0 200px;
  padding: 60px 315px;
}

.paypalBtnContainer {
  margin-top: 20px;

  /deep/ .el-button {
    width: 280px;
    height: 46px;
    background: #B4272B;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 18px;
  }
}

.payBtn {
  width: 280px;
  height: 34px;
  background: #B4272B;
  border-radius: 8px;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.newAmountPrice {
  color: #B4272B;
  font-weight: bold;
  font-size: 24px;
  margin: 0 8px 0 10px;
}

.bankPayContainer {
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  margin: 0 100px;
  padding: 95px 60px 30px;
  box-sizing: border-box;

  .bankPayHeaderContainer {
    .left {
      padding-left: 79px;
      width: 490px;

      .addInternationalLogisticsPrice {
        margin-left: 133px;
        margin-bottom: 46px;
      }


      span {
        line-height: 33px;
      }
    }

    .right {
      margin-left: 127px;

      /deep/ .el-form-item__error {
        left: 122px;
        color: #B4272B
      }

      .fromName {
        /deep/ .el-input__inner {
          height: 34px !important;
        }
      }

      .placeholder {
        text-align: right;
        width: 96px;
        min-width: 105px;
        font-size: 16px;
        margin-right: 16px;
        justify-content: flex-end;

        span {
          color: #B4272B;
        }
      }

      .payRemarkText {
        font-size: 12px;
        color: #B4272B;
        line-height: 15px;
        margin-left: 122px;
      }
    }
  }

  .bankListContainer {
    margin-top: 31px;

    .redBorderBankContainer {
      border: 2px solid #B4272B !important;
      position: relative;
    }

    .bankContainer, .redBorderBankContainer {
      width: 320px;
      height: 300px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      padding: 34px 0 0 60px;

      img {
        width: 200px;
        height: 34px;
        margin-bottom: 35px;
      }


      .labelName {
        text-align: right;
        width: 80px;
      }

      .selectBankIcon {
        width: 30px;
        height: 30px;
        background: #B4272B;
        border-radius: 10px 0 10px 0;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -2px;
        color: #fff;
      }
    }
  }

  .paymentTitle {
    color: #B4272B;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
}
</style>
